import './Contacts.scss';
import { Form } from '../Form';
import { Container } from '../Container/Container';

export const Contacts = () => (
  <section className="Contacts">
    <Container>
      <div className="Contacts__Content">
        <div>
          <h2 className="Contacts__Title">Let&#39;s talk asap</h2>

          <h4 className="Contacts__Description">
            We'd love to tell you more about our products and service. Please
            fill in your contact details and we will be in touch with you within
            24 hours
          </h4>

          <article className="Contacts__Links">
            <ul className="Contacts__List">
              <li className="Contacts__Item">
                <img src="./images/phone.svg" alt="phone" />
                <a href="tel:+380 50 300 4283" className="Contacts__Link">
                  +380 50 300 4283
                </a>
              </li>

              <li className="Contacts__Item">
                <img src="./images/mail.svg" alt="mail" />
                <a href="mailto:hello@kebeta.ai" className="Contacts__Link">
                  hello@kebeta.ai
                </a>
              </li>

              <li className="Contacts__Item">
                <img
                  src="./images/gps.svg"
                  alt="map"
                  className="Contacts__Map"
                />
                <a
                  href="https://goo.gl/maps/8ooXmwynHzckNpWXA"
                  className="Contacts__Link"
                  target="_blank"
                  rel="noreferrer"
                >
                  Kolomenska 63, office 3-10 <br />
                  Kharkiv, Ukraine
                </a>
              </li>
            </ul>
          </article>
        </div>

        <div className="Contacts__Form" id="contacts">
          <Form />
        </div>
      </div>
    </Container>
  </section>
);
