import { Container } from '../Container/Container';
import './Header.scss';

export const Header = () => (
  <header className="Header">
    <Container>
      <div className="Header__Content">
        <a href="/">
          <div className="Header__Logo"></div>
        </a>
        <a href="#contacts" className="Header__Button">
          <div className="Header__Button-Icon"></div>
          <span className="Header__Button-Text">Contact Us</span>
        </a>
      </div>
    </Container>
  </header>
);
