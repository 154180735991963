import { Container } from '../Container/Container';
import './Level1.scss';

export const Level1 = () => (
  <section className="Level1" id="level1">
    <Container>
      <div className="Level1__Content">
        <div className="Level1__Image-Container">
          <img
            src="./images/level-1.png"
            alt="Level 1"
            className="Level1__Image"
          />
        </div>
        <div>
          <h2 className="Level1__Title">Level 1: Content Health Checks</h2>

          <ul className="Level1__List">
            <li className="Level1__Item">
              •
              <div>
                Content Health Checks (CHC) is a must-have set of metrics (e.g.
                adult, toxic, abuse, hate speech detection, explicit nudity &
                sex activity, violence, graphic nudity & sex activity, partial
                nudity - swimwear or underwear)
              </div>
            </li>

            <li className="Level1__Item">
              •<div>Both text and images are analyzed for NSFW</div>
            </li>

            <li className="Level1__Item">
              •<div>Custom R&D supersedes Amazon Rekognition in accuracy</div>
            </li>

            <li className="Level1__Item">
              •<div>Understanding text bias has great business importance</div>
            </li>
          </ul>
        </div>
      </div>
    </Container>
  </section>
);
