import { Container } from '../Container/Container';
import './Level2.scss';

const frames = [
  {
    img: './images/info.svg',
    head: 'Informative Value',
    text: 'is a unique grading criterion of data density in a text',
  },
  {
    img: './images/headline.svg',
    head: 'Headline Fairness',
    text: 'measures semantic correspondence between a text and its headline, clickbait protection',
  },
  {
    img: './images/metric.svg',
    head: 'Advertorial / Paid Content Metric',
    text: 'classifies a long-form text into editorial, advertorial, or paid types of content',
  },
];

export const Level2 = () => (
  <section className="Level2" id="level2">
    <Container>
      <h2 className="Level2__Title">
        Level 2: Content Qualitative Metrics for Media
      </h2>

      <ul className="Level2__Frames">
        {frames.map((frame) => (
          <article key={frame.head} className="Level2__Frame">
            <img
              src={frame.img}
              alt={frame.head}
              className="Level2__Frame--Image"
            />

            <p className="Level2__Text">
              <strong className="Level2__Frame--Head">{frame.head}</strong>{' '}
              {frame.text}
            </p>
          </article>
        ))}
      </ul>

      <div className="Level2__Value">
        <div>
          <h3 className="Level2__Subtitle">Informative Value</h3>

          <p className="Level2__Text Level2__Text--Graph">
            Our know-how metric offers a unique approach to measuring
            information density and contextual data, providing valuable insights
            that can help improve your advertising potential. <br /> <br />
            Application of our AI in the evaluation process of 3 independent
            media platforms provides us with detailed data on the article's
            informativity. This value differs due to the presence of articles on
            various topics and one-topic articles.
          </p>
        </div>

        <img src="./images/graph.png" alt="Graph" className="Level2__Graph" />
      </div>

      <div className="Level2__Ad">
        <div>
          <h3 className="Level2__Subtitle">Ad Value</h3>

          <p className="Level2__Text Level2__Text--Graph">
            The metric is a one-of-a-kind tool that enables the classification
            of potential advertising content, along with providing contextual
            data for enhanced advertising potential and targeting precision.
            <br /> <br />
            According to the research of 3 independent media platforms, articles
            that our AI determined as paid were not sponsored.
            <br /> <br />
            We can declare that the analyzed media businesses lose up to 30% of
            ad revenue.
          </p>
        </div>

        <img src="./images/table.png" alt="table" className="Level2__Table" />
      </div>
    </Container>
  </section>
);
