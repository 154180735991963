import { Container } from '../Container/Container';
import './About.scss';

const options = [
  {
    title: '3',
    text: 'level of analysis',
  },
  {
    title: '5',
    text: 'completely unique metrics applied in the analyzing process',
  },
  {
    title: '100+',
    text: 'languages is supported by our Content Health Checks technology',
  },
];

export const About = () => (
  <section id="about" className="About">
    <Container>
      <div className="About__Content">
        <img
          src="./images/laptop.png"
          alt="Kebeta.ai"
          className="About__Image"
        />
        <div>
          <h2 className="About__Title">About the product</h2>
          <p className="About__Text">
            Kebeta.ai is a content quality monitoring system with unique
            metrics. Implementation of our team's know-how allows a deeper and
            better content analysis.
            <br />
            <br />
            Our system consists of 3 levels. Each one performs content analysis
            from its perspective.
            <br />
            <br />
            The goal is to explore the ecology of content. Namely, analyzing
            relations between certain content and other contents on the World
            Wide Web.
          </p>

          <div className="About__Options">
            {options.map((option) => (
              <article className="About__Option" key={option.title}>
                <h3 className="About__Option--Title">{option.title}</h3>
                <p className="About__Option--Text">{option.text}</p>
              </article>
            ))}
          </div>
        </div>
      </div>
    </Container>
  </section>
);
