import { createRoot } from 'react-dom/client';
import { App } from './App';
import './styles/main.scss';

const Root = () => (
  <App />
);

createRoot(document.getElementById('root'))
  .render(<Root />);
