import { Container } from '../Container/Container';
import './Footer.scss';

export const Footer = () => (
  <footer className="Footer">
    <Container>
      <div className="Footer__Content">
        <div className="Footer__Top">
          <div>
            <a href="/">
              <div className="Footer__Logo"></div>
            </a>
            <p className="Footer__About">
              With over 20 years of experience in the IT-industry, we are a
              leading provider of innovative solutions for investors!
            </p>
          </div>

          <nav className="Footer__Nav">
            <ul className="Footer__List">
              <li className="Footer__Item">
                <a href="#about" className="Footer__Link">
                  About
                </a>
              </li>
              <li className="Footer__Item">
                <a href="#level1" className="Footer__Link">
                  Level 1
                </a>
              </li>
              <li className="Footer__Item">
                <a href="#level2" className="Footer__Link">
                  Level 2
                </a>
              </li>
              <li className="Footer__Item">
                <a href="#level3" className="Footer__Link">
                  Level 3
                </a>
              </li>
              <li className="Footer__Item">
                <a href="#services" className="Footer__Link">
                  Additional Services
                </a>
              </li>
            </ul>
          </nav>
        </div>

        <div className="Footer__Copyright">Copyright © 2022</div>
      </div>
    </Container>
  </footer>
);
