import './Review.scss';

export const Review = () => (
  <section className="Review">
    <img
      src="./images/top-img.png  "
      alt="Kebeta AI"
      className="Review__Image"
    />

    <div className="Review__Content">
      <h1 className="Review__Title">AI Content Quality Monitoring System</h1>
      <p className="Review__Text">
        Bring the benefit of AI application in Media to your business. Let's
        contact ASAP and see what solution we have for you
      </p>
      <a href="#contacts" className="Review__Button">
        SCHEDULE A CALL
      </a>
    </div>
  </section>
);
