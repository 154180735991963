import { Container } from '../Container/Container';
import './Level3.scss';

export const Level3 = () => (
  <section className="Level3" id="level3">
    <Container>
      <h2 className="Level3__Title">Level 3: Content Authenticity Checks</h2>

      <div className="Level3__Content">
        <div className="Level3__Row">
          <div className="Level3__Image-Container">
            <img
              src="./images/level-2-1.png"
              alt="Level 1"
              className="Level3__Image"
            />
          </div>

          <div className="Level3__Info">
            <h3 className="Level3__Subtitle">Authenticity Checks</h3>
            <ul className="Level3__List">
              <li className="Level3__Item">
                •
                <div>
                  <strong>Plagiarism & Similarity Detection</strong> - verbatim,
                  paraphrasing, semantic, cross-lingual - all sorts of
                  plagiarisms are to be analysed. The task is well know in a
                  scientific community but not solved in media world
                </div>
              </li>

              <li className="Level3__Item">
                •
                <div>
                  <strong>ChatGPT</strong> and other machine-generated text
                  detection
                </div>
              </li>

              <li className="Level3__Item">
                •
                <div>
                  <strong>Research</strong> on Virality prediction
                </div>
              </li>
            </ul>
          </div>
        </div>

        <div className="Level3__Row">
          <div className="Level3__Image-Container">
            <img
              src="./images/level-2-2.png"
              alt="Level 1"
              className="Level3__Image"
            />
          </div>

          <div className="Level3__Info">
            <h3 className="Level3__Subtitle">Authenticity Checks Technology</h3>
            <ul className="Level3__List">
              <li className="Level3__Item">
                •
                <div>
                  Informative Value based solution via{' '}
                  <strong>
                    semantic extraction, machine translation and search engine
                    integration
                  </strong>{' '}
                  (Kebeta.ai team know-how)
                </div>
              </li>

              <li className="Level3__Item">
                •
                <div>
                  Fake news detection approach via catching a machine-generated
                  text to prevent fake news spreading
                </div>
              </li>

              <li className="Level3__Item">
                •
                <div>
                  Ongoing research in partnership with Swiss academic
                  institutions
                </div>
              </li>
            </ul>
          </div>
        </div>
      </div>
    </Container>
  </section>
);
