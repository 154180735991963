import { Container } from '../Container/Container';
import './Technology.scss';

export const Technology = () => (
  <section className="Technology">
    <Container>
      <h2 className="Technology__Title">Technology explained</h2>

      <ul className="Technology__List">
        <li className="Technology__Frame">
          <img
            src="./images/search.svg"
            alt="Natural Language Understanding"
            className="Technology__Image"
          />
          <h3 className="Technology__Text">
            <strong className="Technology__Bold">
              Natural Language Understanding, semantic-
            </strong>
            based analysis
          </h3>
        </li>

        <li className="Technology__Frame">
          <img
            src="./images/world.svg"
            alt="Multilingual"
            className="Technology__Image"
          />
          <h3 className="Technology__Text">
            <strong className="Technology__Bold">Multilingual </strong>
            (over 100 languages supported)
          </h3>
        </li>

        <li className="Technology__Frame">
          <img
            src="./images/network.svg"
            alt="Transfer Learning"
            className="Technology__Image"
          />
          <h3 className="Technology__Text">
            <strong className="Technology__Bold">Transfer Learning </strong>
            and state-of-the-art{' '}
            <strong className="Technology__Bold">Transformer</strong> architectures
            utilized
          </h3>
        </li>

        <li className="Technology__Frame">
          <img
            src="./images/group.svg"
            alt="proprietary neural networks"
            className="Technology__Image"
          />
          <h3 className="Technology__Text">
            Tailor-made{' '}
            <strong className="Technology__Bold">
              proprietary neural networks
            </strong>{' '}
            developed
          </h3>
        </li>

        <li className="Technology__Frame">
          <img
            src="./images/cpu.svg"
            alt="Explainable AI"
            className="Technology__Image"
          />
          <h3 className="Technology__Text">
            <strong className="Technology__Bold">Explainable AI </strong>
            both for text and images
          </h3>
        </li>
      </ul>
    </Container>
  </section>
);
