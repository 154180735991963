import { Container } from '../Container/Container';
import './Services.scss';

const frames = [
  {
    img: './images/cpu.svg',
    title: 'Natural Language Understanding',
    text: '55% of tech companies report current benefits to revenue growth from AI initiatives. Our products and services will help you to join them. Our experienced team will deliver a tailored AI solution for your particular need through tailored research and development. No all-purpose tools and public, ready APIs. Your problem - our solution!',
  },
  {
    img: './images/computer.svg',
    title: 'Computer Vision',
    text: 'Video and images are an essential part of business in- or outbound data. We can help your business build an automated analysis pipeline for this type of data.',
  },
  {
    img: './images/deep.svg',
    title: 'Deep Reinforcement Learning',
    text: 'The exploratory nature of the algorithms behind our products and services ensures they learn all the time to provide much safer strategies and decisions. It is enabled by taking into account rapidly changing conditions and tackling edge and unexpected situations.',
  },
  {
    img: './images/circle.svg',
    title: 'Knowledge graphs & Ontologies',
    text: 'Alternative data-based knowledge graphs allow the discovery of deep correlations between entities.',
  },
  {
    img: './images/image.svg',
    title: 'Image generation & processing',
    text: 'What about generating cats? Everyone loves cats! Let us do it for your business.',
  },
  {
    img: './images/analysis.svg',
    title: 'Behavior analysis',
    text: `Your business has lots of customer behavior data: purchases, visits, and preferences. It's time to convert it into valuable marketing insight.`,
  },
];

export const Services = () => (
  <section className="Services" id="services">
    <Container>
      <h2 className="Services__Title">
        Additional Services & Customer AI Development
      </h2>

      <ul className="Services__Frames">
        {frames.map((frame) => (
          <li className="Services__Frame" key={frame.title}>
            <img src={frame.img} alt={frame.title} />

            <div>
              <h4 className="Services__Frame--Title">{frame.title}</h4>
              <p className="Services__Frame--Text">{frame.text}</p>
            </div>
          </li>
        ))}
      </ul>
    </Container>
  </section>
);
