import './App.scss';
import { About } from './components/About/About';
import { Contacts } from './components/Contacts/Contacts';
import { Footer } from './components/Footer/Footer';
import { Header } from './components/Header/Header';
import { Level1 } from './components/Level1/Level1';
import { Level2 } from './components/Level2/Level2';
import { Level3 } from './components/Level3/Level3';
import { Review } from './components/Review/Review';
import { Services } from './components/Services/Services';
import { Technology } from './components/Technology/Technology';

export const App = () => (
  <>
    <div style={{ position: 'relative' }}>
      <div className="App__Background App__Background--1"></div>
      <Header />
      <Review />
    </div>
    <main className="App">
      <div style={{ position: 'relative' }}>
        <div className="App__Background App__Background--3"></div>
        <About />
      </div>
      <div style={{ position: 'relative' }}>
        <div className="App__Background App__Background--2"></div>
        <Technology />
        <Level1 />
      </div>
      <Level2 />
      <div style={{ position: 'relative' }}>
        <div className="App__Background App__Background--4"></div>
        <div className="App__Background App__Background--5"></div>
        <Level3 />
      </div>
      <Services />
      <Contacts />
    </main>
    <Footer />
  </>
);
